/**
 * Created by henian.xu on 2019/8/16.
 *
 */

import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(
  'cart',
);
export default {
  computed: {
    ...mapGetters([
      'cartList',
      'supplierList',
      'supplierMap',
      'currentSupplier',
      'totalAmount',
      'getSelectedData',
    ]),
  },
  methods: {
    ...mapMutations([
      'supplierSelect',
      'cartSelect',
      'deleteCart',
      'updateCart',
      'setSelected',
      'restoreSelected',
    ]),
    ...mapActions(['editCart', 'deleteCart', 'updated']),
  },
};
