<!-- Created by henian.xu on 2018/4/18. -->

<template>
  <linker
    class="cart-goods-item"
    :to="`/goods/detail/${data.goodsId}`"
  >
    <checkbox
      :disabled="editing?false:data.goodsStatus!==3 || !maxBuyQuantity"
      v-model="model"
    />
    <div
      class="img-wrap"
      :style="`background-image: url('${$options.filters.imgCropping(data.specImageUrl || $defaultImg,{width:350})}');`"
    >
      <div
        v-if="data.goodsStatus!==3 ||!maxBuyQuantity"
        class="sold-out"
      />
    </div>
    <div class="inner">
      <div class="header">
        <div class="name">
          {{ data.name }}
        </div>
        <!--<div
                    v-if="editing || data.goodsStatus===3"
                    class="del btn btn-red small"
                    v-show="editing"
                    @click="onDelete"
                >
                    删除
                </div>-->
      </div>
      <div
        class="sku"
        v-if="data.specDesc"
      >
        <span>{{ data.specDesc }}</span>
      </div>
      <div class="price-wrap">
        <div class="price fs-big">
          {{ data.price | price }}
        </div>
        <input-number
          v-if="data.goodsStatus===3 && maxBuyQuantity"
          v-model="buyQuantity"
          :min="minBuyQuantity"
          :max="maxBuyQuantity"
          @plusdisabled="$messageBox.tips('已超过最大库存数量!')"
        />
      </div>
    </div>
  </linker>
</template>

<script>
export default {
  name: 'CartGoodsItem',
  data() {
    return {
      buyQuantity: this.data.quantity,
      emitChange_: this.$utils.Comm.debounce(this.emitChange),
    };
  },
  props: {
    value: {
      type: [Boolean, Number],
      default: false,
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    editing: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    maxBuyQuantity() {
      let res = NaN;
      const stock = this.data.showStock;
      if (
        stock < this.data.maxPerOrderQuantity ||
        !this.data.maxPerOrderQuantity
      ) {
        // this.tips = '已超过最大库存数量!';
        res = stock;
      } else {
        // this.tips = '已超过最大购买数量!';
        res = this.data.maxPerOrderQuantity;
      }
      return res;
    },
    minBuyQuantity() {
      return this.data.minPerOrderQuantity || 1;
    },
  },
  watch: {
    buyQuantity() {
      this.emitChange_();
    },
  },
  methods: {
    onDelete() {
      this.$emit('delete');
    },
    async emitChange() {
      const { data, buyQuantity } = this;
      this.$emit('change', {
        id: data.id,
        quantity: buyQuantity,
      });
    },
  },
};
</script>

<style lang="scss">
.cart-goods-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  padding: $padding;
  border-top: 1px solid $color-border;
  background-color: #fff;
  &:first-child {
    border-top: 0;
  }
  > .checkbox {
    align-self: center;
  }
  > .img-wrap {
    flex: 0 0 auto;
    width: 1.6rem;
    height: 1.6rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-right: $margin;
    position: relative;

    > .sold-out {
      position: absolute;
      top: 0;
      left: 0;
      //background: rgba(0, 0, 0, 0.5) url('~@/assets/images/comm/icon04.png') center no-repeat;
      background-size: 70%;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &:before {
        content: '补货中';
        font-size: 90%;
        padding: $padding-small ($padding);
        letter-spacing: 0.2em;
        border-radius: 100vw;
        color: #fff;
        background-color: rgba(0, 0, 0, 0.5);
      }
    }
  }
  > .inner {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    > .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      > .name {
        flex: 1 1 auto;
        line-height: 1.2;
        font-size: 0.3rem;
        @include text-line(2);
      }
      > .del {
        flex: 0 0 auto;
        white-space: nowrap;
        margin-left: $margin-small/2;
      }
    }
    > .sku {
      > span {
        display: inline-block;
        font-size: 0.22rem;
        line-height: 1.5;
        padding: 0 $padding-small;
        border: 1px solid $color-main;
        color: $color-main;
        border-radius: (0.22rem * 1.5)/2;
      }
    }
    > .price-wrap {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
    }
  }
}
</style>
